import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import AboutUs from '../components/AboutUs.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  }
]

const router = createRouter({
  history: createWebHashHistory(),  // 改用 hash 模式
  routes
})

export default router
