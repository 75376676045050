<template>
  <div class="screen first-screen">
  </div>
</template>

<script>
export default {
  name: 'FirstScreen'
}
</script>

<style scoped>
.first-screen {
  background-image: url('@/assets/first-screen-bg.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 1200px;  /* 从 900px 改为 1200px */
  margin: 0 auto;
  min-height: 5141px;  /* 同比例调整高度：3856 * (1200/900) */
}

@media (max-width: 768px) {
  .first-screen {
    background-image: url('@/assets/first-screen-bg-small.webp');
    min-height: auto;
    aspect-ratio: 750 / 3856;  /* 保持原有比例 */
  }
}
</style>
