<template>
  <div class="home-page">
    <FirstScreen />
    <!-- <SecondScreen /> -->
    <div class="qr-float">
      <img src="@/assets/qr.png" alt="二维码" />
    </div>
    <div class="float-button" @click="handleBuyNow">立即抢购</div>
  </div>
</template>

<script>
import FirstScreen from '@/components/FirstScreen.vue'
// import SecondScreen from '@/components/SecondScreen.vue'

export default {
  name: 'HomePage',
  components: {
    FirstScreen
    // SecondScreen
  },
  methods: {
    handleBuyNow() {
      window.location.href = 'https://detail.tmall.com/item.htm?abbucket=8&id=854936553451&ns=1&pisk=gmAIFwV2R3dZtGikIH0N5uxxuwC7dVl4Az_JoUFUy6Cd2dTDVMuoK6J1VhK1vWunKTIWx6BkLgSFV_TkcVoqgj8H-_fK0ml4sPaLSs2d2_EzBNQFNwhI_Aup-_f-VPP-3jTHDSNGr7QRWV_Pz7ep29EO6ZIOy7CJ2GBOkZrdw3C-5RQFPaFLpMhOBGbLp7edvRFOuaV8p9KJWV_lXgCRef9alaMCxegKBgD5afHe7GN8wC_sqiLI-776sw_CcOLYw73lR9svJGnmvTiGFH6B_XZcRdLXjwtoiWfdFppA9QijO1LDQHQ62mw5XKOHNtRIc-jptgxf9diTO_ChlU62Q4efbLLM1OOI3zbMTUdh3sh0vgJwlQ_WZ0c6ceKBHtdLAg53giTF08a1n7_10Vg_E82xnehyE5mqm9QGROus5kplpNb1SVg_E8XdSNPx5VZpE&priceTId=2147830217410178332717474ebcea&skuId=5826148339405&spm=a21n57.1.hoverItem.2&utparam=%7B%22aplus_abtest%22%3A%2226286383a10cd7be72bf06f45cf1ca9a%22%7D&xxc=taobaoSearch'
    }
  }
}
</script>

<style scoped>
.home-page {
  position: relative;
}

.qr-float {
  position: fixed;
  bottom: 120px;
  right: 40px;
  padding: 10px;
  z-index: 1000;
}

.qr-float img {
  width: 120px;
  height: 120px;
  display: block;
}

.float-button {
  position: fixed;
  bottom: 60px;  /* 从 40px 改为 60px */
  right: 40px;
  background-color: #ff4400;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
}

.float-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.float-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .float-button {
    bottom: 40px;  /* 从 20px 改为 40px */
    right: 20px;
    padding: 12px 24px;
    font-size: 16px;
  }

  .qr-float {
    bottom: 90px;
    right: 20px;
  }

  .qr-float img {
    width: 100px;
    height: 100px;
  }
}
</style>
